class ContentBodyBase {
	constructor() {
		this.dependencies = [];
		this.references = [];
		this.attachments = [];
		this.contents = {};
		this.metadata = {};
	}
	addReference(type, _id, major_revision, minor_revision) {
		this.references.push({ $ref: `plant-${type}:/content/${_id}/${major_revision}/${minor_revision}`, $id: 0 });
	}
}
/**
 * Content attachment
 */
export class Attachment {
	/**
	 * Constructor for Class Attachment
	 * @param {String} name
	 * @param {File | String} file
	 * @param {String} url
	 * @param {String} attachment_type
	 */
	constructor(name, file, url, attachment_type) {
		this._id = '' + Math.random();
		this.name = name;
		this.file = file;
		this.url = url;
		this.attachment_type = attachment_type;
		this.metadata = {};
	}
}

function getFileExtension(file) {
	var matches = file.name.match(/^(.*)\.(.*)$/);
	var [, , files_extension] = matches;
	return files_extension ? `.${files_extension.toLowerCase()}` : '';
}

/********************************************************
 * Content Types and Bulk Upload
 ********************************************************
 * To use the bulk upload, the ContentBody class can have an static property
 * named `BULK_UPLOAD`. That property should contain an Object with one of 
 * the following interfaces:
 * 
 *  ```ts
 * interface BulkUpload {
 *  method: string
 * }
 * interface BulkUploadFiles implements BulkUpload{
 *   method: "files" 
 *   extensions: array<string> // array of allowed extensions to upload
 *  
 * }
 * interface BulkUploadSpreadsheet implements BulkUpload{
 *   method: "spreadsheet" 
 *   columns: array<string> // array of columns to add to the spreadsheet,
 *   attachments: array< {
 *      name: string,
 *      required: boolean
 *      }>]
 * }
 * interface BulkUploadSpreadsheetWithReference implements BulkUpload{
 *   method: "spreadsheet-with-reference" 
 *   columns: function(reference:array<string>) // function that receives the reference and returns an array with the column names
 * }

 *  ```
 */
export class ContentBodyDocument extends ContentBodyBase {
	static CONTENT_TYPE = 'document';
	static CONTENT_TYPE_NAME = 'Document';
	static BULK_UPLOAD = {
		method: 'files',
		extensions: ['pdf', 'tiff', 'tif']
	};
	constructor(file) {
		super();
		let url = URL.createObjectURL(file);
		this.attachments.push(new Attachment('document', file, url, getFileExtension(file)));
		this.contents = {};
	}
}

export class ContentBodyPicture extends ContentBodyBase {
	static CONTENT_TYPE = 'picture';
	static CONTENT_TYPE_NAME = 'Picture';
	static BULK_UPLOAD = {
		method: 'files',
		extensions: ['jpg', 'png', 'jpeg']
	};
	constructor(file) {
		super();
		let url = URL.createObjectURL(file);
		this.attachments.push(new Attachment('picture', file, url, getFileExtension(file)));
		this.contents = {};
	}
}

export class ContentBodyVideo extends ContentBodyBase {
	static CONTENT_TYPE = 'video';
	static CONTENT_TYPE_NAME = 'Video';
	static BULK_UPLOAD = {
		method: 'files',
		extensions: ['mp4']
	};
	constructor(file) {
		super();
		let url = URL.createObjectURL(file);
		this.attachments.push(new Attachment('video', file, url, getFileExtension(file)));
		this.contents = {};
	}
}
export class ContentBodyVr extends ContentBodyBase {
	static CONTENT_TYPE = 'vr';
	static CONTENT_TYPE_NAME = 'Virtual Reality';
	static BULK_UPLOAD = {
		method: 'files',
		extensions: ['jpg']
	};
	constructor(file) {
		super();
		let url = URL.createObjectURL(file);
		this.attachments.push(new Attachment('vr', file, url, getFileExtension(file)));
		this.contents = {};
	}
}
export class ContentBodyPointCloud extends ContentBodyBase {
	static CONTENT_TYPE = 'pointCloud';
	static CONTENT_TYPE_NAME = 'Point Cloud';
	static BULK_UPLOAD = {
		method: 'spreadsheet',
		columns: ['server_file_hierarchy', 'server_file_octree', 'server_file_metadata']
	};
	constructor({ server_file_hierarchy, server_file_octree, server_file_metadata }) {
		super();
		this.attachments.push(new Attachment('hierarchy', `server-file:${server_file_hierarchy}`, '', '.bin'));
		this.attachments.push(new Attachment('octree', `server-file:${server_file_octree}`, '', '.bin'));
		this.attachments.push(new Attachment('metadata', `server-file:${server_file_metadata}`, '', '.json'));
		this.contents = {};
	}
}

export class ContentBodyActivityMultipleChoice extends ContentBodyBase {
	static CONTENT_TYPE = 'activityMultipleChoice';
	static CONTENT_TYPE_NAME = 'Activity Multiple Choice';
	static BULK_UPLOAD = {
		method: 'spreadsheet',
		columns: [
			'question',
			'answer',
			'explanation',
			'distractor_1',
			'distractor_2',
			'distractor_3',
			'position',
			'objective'
		],
		attachments: [
			{
				name: 'Picture',
				required: false,
				accept: '.png,.jpg'
			}
		]
	};
	constructor({ question, answer, explanation, distractor_1, distractor_2, distractor_3, position, objective }) {
		super();
		this.contents = {
			question: question ? question + '' : '',
			answer: answer ? answer + '' : '',
			explanation: explanation ? explanation + '' : '',
			distractors: [
				distractor_1 ? distractor_1 + '' : '',
				distractor_2 ? distractor_2 + '' : '',
				distractor_3 ? distractor_3 + '' : ''
			],
			instructionalSettings: {
				completionTime: 0,
				objective
			},
			position
		};
	}
}
export class ContentBodyActivityFlashCard extends ContentBodyBase {
	static CONTENT_TYPE = 'activityFlashCard';
	static CONTENT_TYPE_NAME = 'Activity Flash Card';
	static BULK_UPLOAD = {
		method: 'spreadsheet',
		columns: ['question', 'answer']
	};
	static SPREADSHEET_COLUMNS = ['question', 'answer'];
	constructor({ question, answer }) {
		super();
		this.contents = {
			flashcard: [
				{
					question: question ? question + '' : '',
					answer: answer ? answer + '' : ''
				}
			]
		};
	}
}
export class ContentBodyActivityFillTheGap extends ContentBodyBase {
	static CONTENT_TYPE = 'activityFillTheGap';
	static CONTENT_TYPE_NAME = 'Activity Fill The Gap';
	static BULK_UPLOAD = {
		method: 'spreadsheet',
		columns: ['question']
	};
	static SPREADSHEET_COLUMNS = ['question'];
	constructor({ question }) {
		super();
		this.contents = { question: question ? question + '' : '' };
	}
}
export class ContentBodyObjective extends ContentBodyBase {
	static CONTENT_TYPE = 'objective';
	static CONTENT_TYPE_NAME = 'Objective';
	static BULK_UPLOAD = {
		method: 'spreadsheet',
		columns: [
			'text',
			'comments',
			'condition',
			'standard',
			'level',
			'media',
			'setting',
			'classification',
			'time',
			'topic'
		]
	};
	constructor({ text, comments, condition, standard, level, media, setting, classification, time, topic }) {
		super();
		this.contents = {
			text,
			comments,
			condition,
			standard,
			level,
			media,
			setting,
			classification,
			time,
			topic
		};
	}
}

export class ContentBodyDatasheet extends ContentBodyBase {
	static CONTENT_TYPE = 'datasheet';
	static CONTENT_TYPE_NAME = 'Datasheet';
	static BULK_UPLOAD = {
		method: 'spreadsheet-with-reference',
		reference_content_type: 'datasheetTemplate',
		reference_title: 'Datasheet template',
		columns: function (reference) {
			return ['introduction', ...Object.keys(reference.body.contents.properties)];
		}
	};
	constructor({ introduction, ...datasheet }, $ref) {
		super();
		this.contents.schema = { $ref, $id: '' };
		this.contents.introduction = introduction;
		this.contents.datasheet = datasheet;
	}
}

export class ContentBodyTerm extends ContentBodyBase {
	static CONTENT_TYPE = 'term';
	static CONTENT_TYPE_NAME = 'Term';
	static BULK_UPLOAD = {
		method: 'spreadsheet',
		columns: ['term', 'description', 'acronym']
	};
	constructor({ term, description, acronym }) {
		super();
		this.contents = { term, description, acronym };
	}
}

export class ContentBodyDiagram extends ContentBodyBase {
	static CONTENT_TYPE = 'diagram';
	static CONTENT_TYPE_NAME = 'Diagram';
	static BULK_UPLOAD = {
		method: 'files',
		extensions: ['vsdx', 'svg']
	};
	constructor(file) {
		super();
		this.addAttachment(file);
		this.contents = {
			hotspots: [],
			shapes: {}
		};
	}
	addAttachment(file) {
		const url = URL.createObjectURL(file);
		const extension = getFileExtension(file);
		this.attachments.push(new Attachment(extension.replace('.', ''), file, url, extension));
	}
}