<template>
  <div :style="style">
    <video v-if="value.data.video" width="100%" :ref="`videoPlayer-${value.id}`"
      class="p-block-video-viewer vjs-big-play-centered video-js" :loop="value.data.loop">
      <template v-for="(track, key) in videoSubtitles">
        <track v-if="track" :key="key" :label="subtitlesLang[key]" kind="subtitles" :srclang="key" :src="track.url" />
      </template>
    </video>
  </div>
</template>

<script>
import videojs from "video.js"
import "video.js/dist/video-js.css"
import { availableLanguages } from "./availableLanguages.js"

export default {
  props: ["value", "mode"],
  data() {
    return {
      player: null,
      subtitlesLang: availableLanguages
    }
  },
  computed: {
    style() {
      return {
        "--audio-video-controls-color": this.value.data.controls.color,
        "--audio-video-controls-size": `${this.value.data.controls.size}px`,
        "--audio-video-controls-button-zoom": this.value.data.controls.size / 25
      }
    },
    options() {
      return {
        controls: true,
        width: this.value.size.width,
        height: this.value.size.height,
        sources: [
          {
            src: this.value.data.video ? this.value.data.video.url : null,
            type: this.value.data.video ? this.value.data.video.type : null
          }
        ]
      }
    },
    videoSubtitles() {
      return this.value.data.subtitles
    },
    parentSubcanvasDelay() {
			return this.$parent.$parent.$attrs.subcanvasDelay ? this.$parent.$parent.$attrs.subcanvasDelay : 0
		}
  },
  mounted() {
    this.$nextTick(() => {
      const ref = this.$refs[`videoPlayer-${this.value.id}`]
      if (ref) {
        const context = this
        this.player = videojs(ref, this.options, function onPlayerReady() {
          this.on("click", function (evt) {
            evt.stopPropagation()
          })
          if (context.value.data.autoplay && context.mode == "view") {
            setTimeout(() => {
              context.player.play()
            }, (context.value.data.delay + context.parentSubcanvasDelay) * 1000)
          }
        })
      }
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style>
.p-block-video-viewer.video-js {
  color: var(--audio-video-controls-color);
}

/* Change the border of the big play button. */
.p-block-video-viewer .vjs-big-play-button {
  border-color: var(--audio-video-controls-color);
  zoom: var(--audio-video-controls-button-zoom);
}

/* Change the color of various "bars". */
.p-block-video-viewer .vjs-volume-level,
.p-block-video-viewer .vjs-play-progress,
.p-block-video-viewer .vjs-slider-bar {
  background: var(--audio-video-controls-color);
}
.p-block-video-viewer .vjs-control-bar {
  font-size: var(--audio-video-controls-size);
}

.p-block-video-viewer .vjs-text-track-cue {
  inset: 90% 0 0 0 !important;
  height: 10% !important;
  width: 97% !important;
}
</style>