<template>
  <v-navigation-drawer
    :value="true"
    app
    left
    width="230px"
    :mini-variant="value"
    disable-resize-watcher
    hide-overlay
    permanent
    style="z-index: 6">
    <v-toolbar class="elevation-0 transparent">
      <v-toolbar-side-icon v-if="value" @click="e => $emit('input', !value)">
        <img :src="require(`./icon-purple.svg`)" height="28px" style="vertical-align: middle; cursor: pointer" />
      </v-toolbar-side-icon>
      <v-toolbar-title v-else>
        <img
          @click="e => $emit('input', !value)"
          :src="require(`./logo-purple.svg`)"
          height="45px"
          style="vertical-align: middle; cursor: pointer" />
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-list>
      <template v-for="section in menu_sections">
        <v-divider :key="section.text" v-if="section.divider"></v-divider>
        <template v-else-if="section.items && section.items.length && !value && !section.hide">
          <v-list-group :key="section.title" v-model="section.active" :prepend-icon="section.action" no-action>
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon>{{ section.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ section.text }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>

            <v-list-tile v-for="subSection in section.items" :key="subSection.title">
              <v-list-tile-action>
                <v-icon>{{ subSection.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ subSection.text }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action v-if="subSection.chip">
                <v-chip>5</v-chip>
              </v-list-tile-action>
            </v-list-tile>
          </v-list-group>
        </template>
        <template v-else-if="!section.hide">
          <v-tooltip right :key="section.text">
            <template v-slot:activator="{ on }">
              <v-list-tile link :to="section.to" v-on="on" e2e-attr="plant-section">
                <v-list-tile-action v-if="section.icon">
                  <v-icon>{{ section.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-avatar v-if="section.avatar" :tile="section.avatar.tile">
                  <img v-if="typeof section.avatar == 'string'" :src="section.avatar" alt />
                  <v-avatar v-else :color="section.avatar.color" :tile="section.avatar.tile" size="30px">
                    <span class="white--text">{{ section.avatar.text }}</span>
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ section.text }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <span>{{ section.text }}</span>
          </v-tooltip>
        </template>
      </template>
    </v-list>
    <v-spacer></v-spacer>
    <v-list v-if="isAdmin">
      <v-subheader> Administrator </v-subheader>
      <template v-for="section in admin_menu_sections">
        <v-divider :key="section.text" v-if="section.divider"></v-divider>
        <template v-else-if="section.items && section.items.length && !value && !section.hide">
          <v-list-group :key="section.title" v-model="section.active" :prepend-icon="section.action" no-action>
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon>{{ section.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ section.text }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>

            <v-list-tile v-for="subSection in section.items" :key="subSection.title">
              <v-list-tile-action>
                <v-icon>{{ subSection.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ subSection.text }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action v-if="subSection.chip">
                <v-chip>5</v-chip>
              </v-list-tile-action>
            </v-list-tile>
          </v-list-group>
        </template>
        <template v-else-if="!section.hide">
          <v-tooltip right :key="section.text">
            <template v-slot:activator="{ on }">
              <v-list-tile link :to="section.to" v-on="on" e2e-attr="plant-section">
                <v-list-tile-action v-if="section.icon">
                  <v-icon>{{ section.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-avatar v-if="section.avatar" :tile="section.avatar.tile">
                  <img v-if="typeof section.avatar == 'string'" :src="section.avatar" alt />
                  <v-avatar v-else :color="section.avatar.color" :tile="section.avatar.tile" size="30px">
                    <span class="white--text">{{ section.avatar.text }}</span>
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ section.text }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <span>{{ section.text }}</span>
          </v-tooltip>
        </template>
      </template>
    </v-list>

    <v-spacer></v-spacer>
    <v-list style="position: absolute; bottom: 0px; left: 0px; right: 0px">
      <template v-for="section in bottom_items">
        <v-divider :key="section.text" v-if="section.divider"></v-divider>
        <v-list-tile
          :key="section.text"
          v-else-if="!section.hide"
          link
          :to="section.to"
          :href="section.href"
          :target="section.target"
          e2e-attr="plant-management">
          <v-list-tile-action v-if="section.icon">
            <v-icon>{{ section.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-avatar v-if="section.avatar" :tile="section.avatar.tile">
            <img v-if="typeof section.avatar == 'string'" :src="section.avatar" alt />
            <v-avatar v-else :color="section.avatar.color" :tile="section.avatar.tile" size="30px">
              <span class="white--text">{{ section.avatar.text }}</span>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ section.text }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { STORE_USER_NAME, STATE_PROFILE } from "@/store_constants/user"

import { roles } from "@/config/constants"

export default {
  props: ["value"],
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    isStudent() {
      return this.profile.role === roles.student.value
    },
    isAdmin() {
      return this.profile.role === roles.admin.value
    },
    menu_sections() {
      return [
        {
          icon: "mdi-developer-board",
          text: "Dashboard",
          to: `/dashboard`
        },
        {
          icon: "mdi-package-variant",
          text: "Collections Catalog",
          to: `/collection`
        },
        {
          icon: "mdi-google-classroom",
          text: "Training Catalog",
          to: `/training`
        },
        {
          icon: "mdi-folder-multiple-outline",
          text: "Content Explorer",
          to: `/explorer`
        },
        {
          icon: "mdi-clipboard-check-outline",
          text: "Workflows",
          to: { name: "Workflows" },
          hide: this.isStudent
        }
      ]
    },
    admin_menu_sections() {
      return [
        {
          icon: "mdi-police-badge",
          text: "Configuration Control",
          to: `/configuration`,
          hide: false
        },
        {
          icon: "mdi-hammer-wrench",
          text: "Content Tools",
          to: `/tools`,
          hide: false
        },
        {
          icon: "mdi-hexagon-multiple-outline",
          text: "Background Jobs",
          to: `/background-jobs`,
          hide: false
        },
        {
          icon: "mdi-trash-can-outline",
          text: "Recycle Bin",
          to: "/recycle-bin",
          hide: false
        }
      ]
    },
    bottom_items() {
      return [
        { divider: true },
        {
          icon: "mdi-cog",
          text: "Settings",
          to: `/settings`
        },
        {
          icon: "mdi-help-circle-outline",
          href: "https://docs.myplanttraining.com/",
          target: "_blank",
          text: "User's Manual"
        }
      ]
    }
  }
}
</script>

<style></style>
