<script>
import { cleanHTMLContent } from './utils';
const TEXT_ANNOTATION = 'text';

export default {
	computed: {
		isEmbedded() {
			return this.mode === 'embedded';
		}
	},
	methods: {
		hideUnneededAnnotations() {
			if (!this.selectedTag) return;

			const annotationHideArray = [];

			for (const hotspot of this.hotspots) {
				if (hotspot.type === TEXT_ANNOTATION) continue;

				const hotspotIsMyself = hotspot.value.toLowerCase() === this.selectedTag.toLowerCase();
				if (hotspotIsMyself) continue;

				const pdftron_annotation_hide = this.annotationManager.getAnnotationById(hotspot.annotation_id);
				if (!pdftron_annotation_hide) continue;

				annotationHideArray.push(pdftron_annotation_hide);
			}

			this.annotationManager.hideAnnotations(annotationHideArray);
		},
		async importAnnotation(annotation) {
			const regexAdd = new RegExp(/<add>.*<\/add>/gs);

			if (regexAdd.test(annotation.xfdfString)) {
				const regex = /<modify>.*<\/modify>/gs;
				annotation.xfdfString = annotation.xfdfString.replace(regex, '<modify />');
			}

			await this.annotationManager.importAnnotationCommand(annotation.xfdfString);
			// Set contents to pdftron annotation if needed (fix xfdfstring free text type)
			const pdftron_annotation = this.annotationManager.getAnnotationById(annotation.annotation_id);

			if (pdftron_annotation) {
				pdftron_annotation.setContents(cleanHTMLContent(annotation.value));
			}
		},
		onAnnotationSelected(hotspots, action) {
			var hotspot_selected = action === 'selected' ? this.annotationManager.getSelectedAnnotations()[0] : null;

			// Avoid to open blank drawer
			if (this.mode === 'edit' || (hotspot_selected && hotspot_selected.getContents() !== '')) {
				this.$emit('update:selectedHotspot', hotspot_selected);
			} else {
				this.$emit('update:selectedHotspot', null);
			}
		},
		async onAnnotationChanged(annotations, action, { imported }) {
			// If the event is triggered by importing then it can be ignored
			// This will happen when importing the initial annotations from the server or individual changes from other users
			if (imported || annotations.length === 0) return;
			var xfdfString = await this.annotationManager.exportAnnotationCommand();
			switch (action) {
				case 'delete':
					var deleteIndex = this.hotspots.findIndex(a => a.annotation_id === annotations[0].Id);
					if (deleteIndex !== -1) {
						this.hotspots.splice(deleteIndex, 1);
					}
					this.$emit('update:selectedHotspot', null);
					break;
				case 'add':
					this.hotspots.push({
						annotation_id: annotations[0].Id,
						type: annotations[0].getCustomData('type') || 'text',
						value: annotations[0].getContents() || '',
						xfdfString: xfdfString
					});
					this.$emit('update:selectedHotspot', annotations[0]);
					break;
				case 'modify':
					var modifyIndex = this.hotspots.findIndex(a => a.annotation_id === annotations[0].Id);
					this.hotspots[modifyIndex].xfdfString = xfdfString;
					break;
			}
		},
		async selectAnnotationInPdf(plant_annotation) {
			if (plant_annotation) {
				let pdftron_annotation = this.annotationManager.getAnnotationById(plant_annotation.annotation_id);
				if (pdftron_annotation) {
					this.annotationManager.selectAnnotation(pdftron_annotation);

					pdftron_annotation.setContents(cleanHTMLContent(plant_annotation.value));
				}
			} else {
				this.annotationManager.deselectAllAnnotations();
			}
		}
	}
};
</script>
