<template>
	<v-navigation-drawer
		permanent
		app
		right
		clipped
		width="500px"
		v-if="selectedItemRevisionDraftId || selectedForBulkOperations.length"
		class="detail-drawer"
	>
		<v-toolbar
			color="transparent"
			class="transparent elevation-0"
			height="140px"
			v-if="!selectedForBulkOperations.length"
		>
			<div>
				<h4>
					<v-chip>{{ getCustomId(selectedItemRevisionDraft) }}</v-chip>
				</h4>
				<h2>{{ getTitle(selectedItemRevisionDraft) }}</h2>
				<p class="detail-drawer-path">
					<v-icon>mdi-folder-outline</v-icon>
					<span>{{ getFolderName(selectedItemRevisionDraft) }}</span>
				</p>
			</div>
			<v-spacer />
			<v-btn icon @click="unselectItemRevisionDraft()"><v-icon>mdi-close</v-icon></v-btn>
		</v-toolbar>
		<v-toolbar v-else color="transparent" class="transparent elevation-0" height="40px">
			<v-spacer />
			<v-btn icon @click="unselectItemRevisionDraft()"><v-icon>mdi-close</v-icon></v-btn>
		</v-toolbar>
		<v-container grid-list-xs>
			<v-layout row wrap v-if="selectedItemRevisionDraftId && !selectedForBulkOperations.length">
				<v-flex xs12 py-2 v-if="!selectedForBulkOperations.length">
					<v-chip e2e-id="workflow_state" label color="primary" outline>
						<v-icon left small>mdi-circle</v-icon>
						{{ selectedItemRevisionDraft.approval_process.workflow.state }}
					</v-chip>
					<v-chip color="primary" text-color="white">
						<v-avatar class="primary darken-4">{{ commentsCount }}</v-avatar>
						Comments
					</v-chip>
					<v-chip color="orange" text-color="white">
						<v-avatar class="orange darken-4">
							{{ selectedItemRevisionDraft.approval_process.workflow.warnings.length }}
						</v-avatar>
						Warnings
					</v-chip>
				</v-flex>

				<!-- Workflow --------------------->
				<v-flex xs12>
					<v-select
						e2e-id="workflow_type"
						:items="workflows"
						item-text="label"
						item-value="value"
						label="Workflow"
						hint="This will restart the draft"
						prepend-icon="mdi-sitemap"
						:value="selectedItemRevisionDraft.approval_process.workflow.name"
						@change="handleChangeWorkflow"
						:disabled="!(isAdmin || isSupervisor)"
						append-icon="mdi-chevron-down"
					></v-select>
				</v-flex>

				<!-- Author --------------------->
				<v-flex
					xs12
					v-for="contributor in selectedItemRevisionDraft.approval_process.workflow.contributors"
					:key="contributor.role"
				>
					<user-selector
						e2e-attr="user_selector"
						style="text-transform: capitalize"
						:label="`${contributor.role} ${
							selectedItemRevisionDraft.approval_process.workflow.assigned_to === contributor.role ? '(assignee)' : ''
						}`"
						:placeholder="`Select user for ${contributor.role}`"
						:users-disabled="
							selectedItemRevisionDraft.approval_process.workflow.contributors
								.filter(c => c.user_id !== undefined && c.user_id !== contributor.user_id)
								.map(c => c.user_id)
						"
						:value="contributor.user_id"
						prepend-icon="mdi-clipboard-account"
						@change="user_id => reassignUserRole({ user_id, role: contributor.role, lightweight: true })"
						:disabled="!(isAdmin || isSupervisor)"
						append-icon="mdi-chevron-down"
					></user-selector>
				</v-flex>

				<!-- Action buttons -------------->
				<v-flex xs12>
					<v-btn
						e2e-id="view"
						block
						@click.native="routeItemViewerEditor(selectedItemRevisionDraft)"
						outline
						color="primary"
					>
						<span v-if="canEdit"> <v-icon left>mdi-pencil</v-icon>Edit </span>
						<span v-else> <v-icon left>mdi-eye</v-icon>View </span>
					</v-btn>
					<div v-if="!isInstructor">
						<v-btn
							e2e-id="delete"
							v-if="!selectedItemRevisionDraft.header.from"
							block
							@click.native="deleteItem"
							outline
							color="error"
						>
							<v-icon left>mdi-delete</v-icon>delete
						</v-btn>
						<v-btn e2e-id="delete" v-else block @click.native="discardDraft" outline color="error">
							<v-icon left>mdi-delete</v-icon>discard draft
						</v-btn>
					</div>
					<v-btn block @click.native="viewWorkflow" outline color="primary">
						<v-icon left>mdi-information</v-icon>properties
					</v-btn>
				</v-flex>
			</v-layout>

			<v-layout row wrap v-if="selectedForBulkOperations.length">
				<h4 class="display-1 mb-3">Change workflows</h4>
				<v-flex xs12>
					<v-card tile flat class="selectedItems">
						<v-list dense>
							<v-list-tile v-for="(e, index) in selectedForBulkOperations" :key="index">
								<v-list-tile-action>
									<v-chip e2e-attr="item_custom_id" label small>
										<v-icon left small>mdi-tag</v-icon>
										{{ getCustomId(e) }}
									</v-chip>
								</v-list-tile-action>
								<v-list-tile-content>
									<v-list-tile-title>
										<span v-html="getTitle(e)"></span>
									</v-list-tile-title>
								</v-list-tile-content>
							</v-list-tile>
						</v-list>
					</v-card>
				</v-flex>
				<v-flex xs12 class="mt-3">
					<v-select
						e2e-id="workflow_type"
						:items="workflows"
						item-text="label"
						item-value="value"
						label="Workflow"
						hint="This will restart the draft"
						prepend-icon="mdi-sitemap"
						v-model="selectedBulkWorkflow"
						:disabled="!(isAdmin || isSupervisor)"
						append-icon="mdi-chevron-down"
						return-object
					></v-select>

					<v-btn e2e-id="changeWorkflows" block @click.native="changeSelectedBulkWorkflow" outline color="primary">
						<v-icon left>mdi-pencil</v-icon>
						Make this change on {{ selectedForBulkOperations.length }} elements
					</v-btn>
				</v-flex>

				<v-flex v-if="checkSameWorkflow" xs12>
					<user-selector
						v-for="contributor in selectedForBulkOperations[0].approval_process.workflow.contributors"
						:key="contributor.role"
						e2e-attr="user_selector"
						style="text-transform: capitalize"
						:label="`${contributor.role}`"
						:placeholder="`Select user for ${contributor.role}`"
						:users-disabled="selectedBulkUsers.filter(e => e.role !== contributor.role).map(c => c.user_id)"
						:value="contributor.user_id"
						prepend-icon="mdi-clipboard-account"
						@change="user_id => selectedBulkUser({ user_id, role: contributor.role })"
						:disabled="!(isAdmin || isSupervisor)"
						append-icon="mdi-chevron-down"
					></user-selector>
					<v-btn e2e-id="changeWorkflows" block @click.native="changeSelectedBulkUser" outline color="primary">
						<v-icon left>mdi-pencil</v-icon>
						Make this change on {{ selectedForBulkOperations.length }} elements
					</v-btn>
				</v-flex>
				<v-flex v-else>
					<p class="red--text">These items do not have the same workflow</p>
				</v-flex>

				<v-flex xs12>
					<h4 class="display-1 mt-5 mb-3">Discard workflows</h4>

					<v-btn e2e-id="discard" block @click.native="discardAllDraft" outline color="error">
						<v-icon left>mdi-delete</v-icon>Discard drafts
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>
	</v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { ItemViewer } from 'plant-common';
import UserSelector from './UserSelector';
import { PtModal } from 'plant-common';
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from '@/store_constants/library';
import { STORE_USER_NAME, STATE_PROFILE, GETTER_IS_INSTRUCTOR } from '@/store_constants/user';
import {
	STORE_WORKFLOWS_NAME,
	STATE_SELECTED_ITEM_REVISION_DRAFT_ID,
	STATE_SELECTED_BULK_OPERATIONS,
	GETTER_SELECTED_ITEM_REVISION_DRAFT,
	GETTER_CAN_EDIT,
	GETTER_IS_ADMIN,
	GETTER_IS_SUPERVISOR,
	ACTION_REASSIGN_USER_ROLE,
	ACTION_REASSIGN_USERS_ROLE,
	ACTION_CHANGE_WORKFLOW,
	ACTION_CHANGE_WORKFLOWS,
	ACTION_DELETE_SELECTED_ITEM,
	ACTION_DISCARD_SELECTED_ITEM_DRAFT,
	ACTION_DISCARD_SELECTED_ITEMS_DRAFT,
	ACTION_UNSELECT_ITEM_REVISION_DRAFT,
	ACTION_GET_PROPERTIES_DRAFT,
	ACTION_UPDATE_SELECTED_DRAFT_METADATA
} from '@/store_constants/workflows';
import { getContentId, getTitle, getCustomId, getFolderName, getPath } from '../../../utils/dbTool';
import {
	STORE_EXPLORER_NAME,
	ACTION_GET_ALL_LIBRARY_FOLDERS,
	STATE_FOLDER,
	STATE_ALL_LIBRARY_FOLDERS
} from '@/store_constants/explorer';
import WorkflowTypes from '../constants/WorkflowTypes';

export default {
	components: { ItemViewer, UserSelector },
	mixins: [PtModal],
	data() {
		return {
			workflows: WorkflowTypes,
			selectedBulkWorkflow: false,
			selectedBulkUsers: []
		};
	},
	async created() {
		await this.getAllLibraryFolders();
	},
	computed: {
		...mapState(STORE_WORKFLOWS_NAME, {
			selectedItemRevisionDraftId: STATE_SELECTED_ITEM_REVISION_DRAFT_ID,
			selectedForBulkOperations: STATE_SELECTED_BULK_OPERATIONS
		}),
		...mapState(STORE_LIBRARY_NAME, {
			settings: STATE_PLANT_SETTINGS
		}),
		...mapGetters(STORE_WORKFLOWS_NAME, {
			selectedItemRevisionDraft: GETTER_SELECTED_ITEM_REVISION_DRAFT,
			canEdit: GETTER_CAN_EDIT,
			isAdmin: GETTER_IS_ADMIN,
			isSupervisor: GETTER_IS_SUPERVISOR
		}),
		...mapGetters(STORE_USER_NAME, {
			isInstructor: GETTER_IS_INSTRUCTOR
		}),
		...mapState(STORE_USER_NAME, {
			profile: STATE_PROFILE
		}),
		...mapState(STORE_EXPLORER_NAME, {
			folder: STATE_FOLDER
		}),
		checkSameWorkflow() {
			let ap = null;
			if (this.selectedForBulkOperations && this.selectedForBulkOperations.length) {
				ap = this.selectedForBulkOperations[0].approval_process.workflow.name;
				if (this.selectedForBulkOperations.find(e => e.approval_process.workflow.name !== ap)) return false;
			}
			return true;
		},
		...mapState(STORE_EXPLORER_NAME, {
			allLibraryFolders: STATE_ALL_LIBRARY_FOLDERS
		}),
		itemPath() {
			return getPath(this.selectedItemRevisionDraft, this.allLibraryFolders);
		},
		commentsCount() {
			return this.selectedItemRevisionDraft.approval_process.comments
				? this.selectedItemRevisionDraft.approval_process.comments.filter(comment => !comment.deleted_at).length
				: 0;
		}
	},
	methods: {
		getTitle: getTitle,
		getCustomId: getCustomId,
		...mapActions(STORE_WORKFLOWS_NAME, {
			reassignUserRole: ACTION_REASSIGN_USER_ROLE,
			reassignUsersRole: ACTION_REASSIGN_USERS_ROLE,
			changeWorkflow: ACTION_CHANGE_WORKFLOW,
			changeWorkflows: ACTION_CHANGE_WORKFLOWS,
			deleteSelectedItem: ACTION_DELETE_SELECTED_ITEM,
			discardSelectedItemDraft: ACTION_DISCARD_SELECTED_ITEM_DRAFT,
			discardSelectedItemsDraft: ACTION_DISCARD_SELECTED_ITEMS_DRAFT,
			unselectItemRevisionDraft: ACTION_UNSELECT_ITEM_REVISION_DRAFT,
			getPropertiesDraft: ACTION_GET_PROPERTIES_DRAFT,
			updateSelectedDraftMetadata: ACTION_UPDATE_SELECTED_DRAFT_METADATA
		}),
		...mapActions(STORE_EXPLORER_NAME, {
			getAllLibraryFolders: ACTION_GET_ALL_LIBRARY_FOLDERS
		}),
		getFolderName: getFolderName,
		routeItemViewerEditor(selectedItemRevisionDraft) {
			window.open(`/content/${this.settings._id}/${getContentId(selectedItemRevisionDraft)}/draft`);
		},
		discardAllDraft() {
			this.$modal('confirm', {
				title: `Are you sure you want to discard ${this.selectedForBulkOperations.length} drafts?`,
				accept: {
					text: 'Delete',
					color: 'red',
					icon: 'mdi-delete'
				},
				cancel: {
					text: 'Cancel',
					color: 'default'
				}
			}).then(r => {
				if (r) this.discardSelectedItemsDraft();
			});
		},
		discardDraft() {
			this.$modal('confirm', {
				title: `Are you sure you want to discard this draft?`,
				accept: {
					text: 'Delete',
					color: 'red',
					icon: 'mdi-delete'
				},
				cancel: {
					text: 'Cancel',
					color: 'default'
				}
			}).then(r => {
				if (r) this.discardSelectedItemDraft();
			});
		},
		deleteItem() {
			this.$modal('confirm', {
				title: `Are you sure you want to delete this item?`,
				accept: {
					text: 'Delete',
					color: 'red',
					icon: 'mdi-delete'
				},
				cancel: {
					text: 'Cancel',
					color: 'default'
				}
			}).then(r => {
				if (r) this.deleteSelectedItem();
			});
		},
		async viewWorkflow() {
			let approval_process = await this.getPropertiesDraft(this.selectedItemRevisionDraft);
			if (approval_process.workflow) {
				this.selectedItemRevisionDraft.approval_process = approval_process;
			}
			this.$modal('workflow', {
				title: `Workflow log`,
				approval_process: this.selectedItemRevisionDraft.approval_process,
				workflows: this.workflows,
				item: this.selectedItemRevisionDraft,
				path: this.itemPath,
				header: this.selectedItemRevisionDraft.header,
				isEdit: this.canEdit,
				isDraft: true,
				settings: this.settings
			})
				.then(metadata => {
					if (!metadata) return;

					this.updateSelectedDraftMetadata({
						draft: this.selectedItemRevisionDraft,
						metadata
					});
				})
				.catch(e => console.log(e));
		},
		changeSelectedBulkWorkflow() {
			this.$modal('confirm', {
				title: `Are you sure you want to change workflow to ${this.selectedBulkWorkflow.label} in all selected drafts?`,
				accept: {
					text: 'Confirm',
					color: 'primary',
					icon: 'mdi-check'
				},
				cancel: {
					text: 'Cancel',
					color: 'default'
				}
			}).then(r => {
				if (r) this.changeWorkflows({ name: this.selectedBulkWorkflow.value, lightweight: true });
			});
		},
		selectedBulkUser(user) {
			let index = this.selectedBulkUsers.findIndex(e => e.role === user.role);
			if (index >= 0) {
				this.selectedBulkUsers.splice(index, 1);
			}
			this.selectedBulkUsers.push(user);
		},
		changeSelectedBulkUser() {
			this.$modal('confirm', {
				title: `Are you sure you want to change user roles in all selected drafts?`,
				accept: {
					text: 'Confirm',
					color: 'primary',
					icon: 'mdi-check'
				},
				cancel: {
					text: 'Cancel',
					color: 'default'
				}
			}).then(async r => {
				if (r) {
					for (const user of this.selectedBulkUsers) {
						await this.reassignUsersRole({ ...user, lightweight: true });
					}
				}
			});
		},
		async handleChangeWorkflow(name) {
			await this.changeWorkflow({ name: name, lightweight: true });
			await this.reassignUserRole({ user_id: this.profile._id, role: 'author', lightweight: true });
		}
	}
};
</script>

<style scoped>
.selectedItems {
	height: 170px;
	overflow: auto;
	border: 1px solid #eaeaea;
}
.detail-drawer-path {
	display: flex;
	align-items: center;
}
.detail-drawer-path span {
	margin-left: 5px;
	margin-top: 2px;
	font-size: 14px;
}
</style>
