import PtApi from "../main";
import axios from "axios"
import { injectTokenToVisoFiles } from "./utils";

const interceptors = {
	request: [],
	response: []
};

const $http = axios.create({
	timeout: 600000,
});

$http.interceptors.request.use(axios_config => {
	axios_config["baseURL"] = PtApi.baseURL;
	axios_config.headers["Authorization"] = `Bearer ` + PtApi.token;
	return axios_config;
});

$http.interceptors.response.use(function (response) {
	return injectTokenToVisoFiles(response);
}, function (error) {
	let err = Object.assign({}, error);
	interceptors.response.forEach(f => f(err));
	return Promise.reject(err);
});

export default $http;
