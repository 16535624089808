import { BlockBasic } from "../_models/models.js"

/**
 * A recorded or upload video.
 */
export default class BlockVideo extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "video"
    this.size.width = 980
    this.size.height = 270
    this.data = {
      video: null,
      typeAction: null,
      // autoplay will work in Chrome after user interacts with the page
      // so it works from second slide
      autoplay: false,
      loop: false,
      delay: 0,
      controls: {
        color: "#ffffffff",
        size: 20
      }
    }
  }
}
