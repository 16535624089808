<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <div class="lp-block-video">
        <div class="lp-block-video-edit" v-if="mode == 'edit'">
          <!-- No selection -->
          <div v-if="value.data.typeAction == null" class="lp-block-video-upload-record">
            <block-placeholder
              @click.native="setUploadAction"
              img="add_video"
              icon="mdi-video-plus"
              text="Upload Video" />
            <block-placeholder
              @click.native="setRecordVideoAction"
              img="record_video"
              icon="mdi-record-circle-outline"
              text="Record Video" />
            <block-placeholder
              @click.native="setRecordScreenAction"
              img="record_screen"
              icon="mdi-monitor-screenshot"
              text="Record Screen" />
          </div>
          <div v-else class="lp-block-video-record-upload">
            <!-- Selection record screen -->
            <block-video-record-screen :value="value" v-if="value.data.typeAction == 'recordScreen'" />
            <!-- Selection record video -->
            <block-video-record :value="value" :video="true" v-if="value.data.typeAction == 'recordVideo'" />
            <!-- Selection upload video -->
            <block-video-audio-upload :value="value" :video="true" v-if="value.data.typeAction == 'uploadVideo'" />
          </div>
        </div>
        <!-- View mode -->
        <div class="lp-block-video-view" v-else>
          <block-video-viewer :value="value" mode="view" />
        </div>
      </div>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import BlockToolbarButton from "../button/BlockToolbarButton.vue"
import BlockVideoRecordScreen from "./BlockVideoRecordScreen.vue"
import BlockVideoRecord from "./BlockVideoRecord.vue"
import BlockVideoAudioUpload from "./BlockVideoAudioUpload.vue"
import BlockVideoViewer from "./BlockVideoViewer.vue"
import BlockPlaceholder from "../_components/placeholders/BlockPlaceholder.vue"

export default {
  name: "BlockVideo",
  inject: ["blockController"],
  components: {
    BasicBlock,
    BlockToolbarButton,
    BlockVideoRecordScreen,
    BlockVideoRecord,
    BlockVideoAudioUpload,
    BlockVideoViewer,
    BlockPlaceholder
  },
  props: ["value", "mode"],
  methods: {
    setUploadAction() {
      this.$set(this.value.data, "typeAction", "uploadVideo")
    },
    setRecordVideoAction() {
      this.$set(this.value.data, "typeAction", "recordVideo")
      this.value.size.width = 640
      this.value.size.height = 480
    },
    setRecordScreenAction() {
      this.$set(this.value.data, "typeAction", "recordScreen")
      this.value.size.width = 640
      this.value.size.height = 480
    }
  },
  created() {
    if (!this.value.data.hasOwnProperty("loop")) {
      this.$set(this.value.data, "loop", false)
    }
  }
}
</script>

<style>
.lp-block-video {
  height: 100%;
}
.lp-block-video-upload-record {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.lp-block-video-record-upload {
  width: 100%;
  height: 100%;
}
.lp-block-video audio:focus {
  outline: none;
}
.lp-configure-slide-dialog-window-card .lp-block-video-view {
  pointer-events: none;
}
.lp-block-video-edit {
  height: 100%;
}
</style>
